import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingComponent } from './billing.component';
import { AccountStatementComponent } from './account-statement/account-statement.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaypalPaymentsComponent } from './paypal-payments/paypal-payments.component';

import { AuthGuard } from '@app/_guards';

const routes: Routes = [{ 
	  path: 'billing', 
		component: BillingComponent,
		children: [
			{ path: 'account-statement', component: AccountStatementComponent },
			{ path: 'payment-methods', component: PaymentMethodsComponent },
			{ path: 'paypal-payments', component: PaypalPaymentsComponent },
		],
		canActivate: [AuthGuard],
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
