import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-additional-names',
  templateUrl: './additional-names.component.html',
  styleUrls: ['./additional-names.component.css']
})
export class AdditionalNamesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
