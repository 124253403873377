import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailForwardingRoutingModule } from './mail-forwarding-routing.module';
import { MailForwardingComponent } from './mail-forwarding.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { OnlineMailManagerComponent } from './online-mail-manager/online-mail-manager.component';
import { ShopInCanadaComponent } from './shop-in-canada/shop-in-canada.component';
import { OtherServicesComponent } from './other-services/other-services.component';

@NgModule({
  declarations: [
  	MailForwardingComponent, 
  	HowItWorksComponent, 
  	OnlineMailManagerComponent, 
  	ShopInCanadaComponent, 
  	OtherServicesComponent
  ],
  imports: [
    CommonModule,
    MailForwardingRoutingModule
  ],
  exports: []
})
export class MailForwardingModule { }
