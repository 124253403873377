import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TestimonialsAndReviewsComponent } from './testimonials-and-reviews/testimonials-and-reviews.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BlogsAndNewsComponent } from './blogs-and-news/blogs-and-news.component';

const routes: Routes = [
	{ 
		path: 'about-us', 
		component: AboutUsComponent,
		children: [
			{ path: 'who-we-are', component: WhoWeAreComponent },
			{ path: 'contact-us', component: ContactUsComponent },
			{ path: 'testimonials-and-reviews', component: TestimonialsAndReviewsComponent },
			{ path: 'privacy-policy', component: PrivacyPolicyComponent },
			{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
			{ path: 'blogs-and-news', component: BlogsAndNewsComponent },
		]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutUsRoutingModule { }
