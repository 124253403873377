import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreferencesComponent } from './preferences.component';
import { AccountPreferencesComponent } from './account-preferences/account-preferences.component';
import { AdditionalNamesComponent } from './additional-names/additional-names.component';
import { ShippingAddressesComponent } from './shipping-addresses/shipping-addresses.component';
import { Form1583VerificationComponent } from './form1583-verification/form1583-verification.component';
import { EditAddressComponent } from './edit-address/edit-address.component';

import { AuthGuard } from '@app/_guards';

const routes: Routes = [{ 
	  path: 'preferences', 
		component: PreferencesComponent,
		children: [
			{ path: 'account-preferences', component: AccountPreferencesComponent },
			{ path: 'additional-names', component: AdditionalNamesComponent },
			{ path: 'shipping-addresses', component: ShippingAddressesComponent },
			{ path: 'form1583-verification', component: Form1583VerificationComponent },
			{ path: 'edit-address', component: EditAddressComponent },
		],
		canActivate: [AuthGuard],
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreferencesRoutingModule { }
