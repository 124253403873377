import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PricingRoutingModule } from './pricing-routing.module';
import { PricingComponent } from './pricing.component';
import { MembershipPlansComponent } from './membership-plans/membership-plans.component';
import { ShippingRatesComponent } from './shipping-rates/shipping-rates.component';
import { DimWeightCalculatorComponent } from './dim-weight-calculator/dim-weight-calculator.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';

@NgModule({
  declarations: [PricingComponent, MembershipPlansComponent, ShippingRatesComponent, DimWeightCalculatorComponent, SpecialOffersComponent],
  imports: [
    CommonModule,
    PricingRoutingModule
  ]
})
export class PricingModule { }
