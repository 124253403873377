import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShipmentRoutingModule } from './shipment-routing.module';
import { ShipmentComponent } from './shipment.component';
import { Ship1Component } from './ship1/ship1.component';
import { Ship2Component } from './ship2/ship2.component';
import { Ship3Component } from './ship3/ship3.component';
import { Ship4Component } from './ship4/ship4.component';
import { ShipConfirmComponent } from './ship-confirm/ship-confirm.component';

@NgModule({
  declarations: [ShipmentComponent, Ship1Component, Ship2Component, Ship3Component, Ship4Component, ShipConfirmComponent],
  imports: [
    CommonModule,
    ShipmentRoutingModule
  ]
})
export class ShipmentModule { }
