import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtual-office',
  templateUrl: './virtual-office.component.html',
  styleUrls: ['./virtual-office.component.css']
})
export class VirtualOfficeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
