import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsRoutingModule } from './about-us-routing.module';
import { AboutUsComponent } from './about-us.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TestimonialsAndReviewsComponent } from './testimonials-and-reviews/testimonials-and-reviews.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BlogsAndNewsComponent } from './blogs-and-news/blogs-and-news.component';

@NgModule({
  declarations: [AboutUsComponent, WhoWeAreComponent, ContactUsComponent, TestimonialsAndReviewsComponent, PrivacyPolicyComponent, TermsAndConditionsComponent, BlogsAndNewsComponent],
  imports: [
    CommonModule,
    AboutUsRoutingModule
  ]
})
export class AboutUsModule { }
