import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-forwarding',
  templateUrl: './mail-forwarding.component.html',
  styleUrls: ['./mail-forwarding.component.css']
})
export class MailForwardingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
