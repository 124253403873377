import { Component } from '@angular/core';
import { User } from '@app/_models';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.css']
})
export class LoggedInComponent {
	currentUser: User;

  constructor(private authenticationService: AuthenticationService) {
  	this.authenticationService.currentUser.subscribe(
  		user => {
  			this.currentUser = user;
  		}
  	);
  }
}
