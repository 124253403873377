import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials-and-reviews',
  templateUrl: './testimonials-and-reviews.component.html',
  styleUrls: ['./testimonials-and-reviews.component.css']
})
export class TestimonialsAndReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
