import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderActivityComponent } from './order-activity.component';
import { PendingOrdersComponent } from './pending-orders/pending-orders.component';
import { OrderHistoryComponent } from './order-history/order-history.component';

import { AuthGuard } from '@app/_guards';

const routes: Routes = [
	{ path: 'order-activity', 
	  component: OrderActivityComponent,
	  children: [
	  	{ path: 'pending-orders', component: PendingOrdersComponent },
	  	{ path: 'order-history', component: OrderHistoryComponent },
	  ], 
	  canActivate: [AuthGuard]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderActivityRoutingModule { }
