import { NgModule } from '@angular/core';

import { OrderActivityRoutingModule } from './order-activity-routing.module';
import { OrderActivityComponent } from './order-activity.component';
import { PendingOrdersComponent } from './pending-orders/pending-orders.component';
import { OrderHistoryComponent } from './order-history/order-history.component';

@NgModule({
	declarations: [OrderActivityComponent, PendingOrdersComponent, OrderHistoryComponent],
	imports: [
    OrderActivityRoutingModule
  ]
})
export class OrderActivityModule { }
