import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-customer',
  templateUrl: './become-customer.component.html',
  styleUrls: ['./become-customer.component.css']
})
export class BecomeCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
