import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	submitted = false;

  constructor(
  	private formBuilder: FormBuilder,
  	private router: Router,
  	private authenticationService: AuthenticationService,
  	private userService: UserService,
  	private alertService: AlertService
  ) {
  	// redirect to home if already logged in
  	if (this.authenticationService.currentUserValue) {
  		this.router.navigate(['/']);	
  	}  	
  }

  ngOnInit() {
  	this.registerForm = this.formBuilder.group({
  		firstName: ['', Validators.required],
  		lastName: ['', Validators.required],
  		username: ['', [Validators.required, Validators.email]],
  		password: ['', [Validators.required, Validators.minLength(6)]]
  	});
  }

  // convinience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
  	console.log('submited...');
  	this.submitted = true;

  	// stop here if form is invalid
  	if (this.registerForm.invalid) {
  		return;
  	}

  	this.loading = true;
  	this.userService.register(this.registerForm.value)
  		.pipe(first())
  		.subscribe(
  			data => {
  				this.alertService.sucess('Registration Successfully', true);
  				this.router.navigate(['/login']);
  			},
  			error => {
  				this.alertService.error(error);
  				this.loading = false;
  			}
  		);
  }
}

/*import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '@app/_services';

@Component({templateUrl: 'register.component.html'})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
*/
