import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './billing.component';
import { AccountStatementComponent } from './account-statement/account-statement.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaypalPaymentsComponent } from './paypal-payments/paypal-payments.component';

@NgModule({
  declarations: [BillingComponent, AccountStatementComponent, PaymentMethodsComponent, PaypalPaymentsComponent],
  imports: [
    CommonModule,
    BillingRoutingModule
  ]
})
export class BillingModule { }
