import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreferencesRoutingModule } from './preferences-routing.module';
import { PreferencesComponent } from './preferences.component';
import { AccountPreferencesComponent } from './account-preferences/account-preferences.component';
import { ShippingAddressesComponent } from './shipping-addresses/shipping-addresses.component';
import { Form1583VerificationComponent } from './form1583-verification/form1583-verification.component';
import { AdditionalNamesComponent } from './additional-names/additional-names.component';
import { EditAddressComponent } from './edit-address/edit-address.component';

@NgModule({
  declarations: [PreferencesComponent, AccountPreferencesComponent, ShippingAddressesComponent, Form1583VerificationComponent, AdditionalNamesComponent, EditAddressComponent],
  imports: [
    CommonModule,
    PreferencesRoutingModule
  ]
})
export class PreferencesModule { }
