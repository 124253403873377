import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailForwardingComponent } from './mail-forwarding.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { OnlineMailManagerComponent } from './online-mail-manager/online-mail-manager.component';
import { ShopInCanadaComponent } from './shop-in-canada/shop-in-canada.component';
import { OtherServicesComponent } from './other-services/other-services.component';

const routes: Routes = [
	{ path: 'mail-forwarding',  
		component: MailForwardingComponent, 
		children: [	                                                                   
			{ path: 'how-it-works', component: HowItWorksComponent},
			{ path: 'online-mail-manager', component: OnlineMailManagerComponent},
			{ path: 'shop-in-canada', component: ShopInCanadaComponent},
			{ path: 'other-services', component: OtherServicesComponent },
		]
	}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MailForwardingRoutingModule { }
