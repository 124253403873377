import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogs-and-news',
  templateUrl: './blogs-and-news.component.html',
  styleUrls: ['./blogs-and-news.component.css']
})
export class BlogsAndNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
