import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShipmentComponent } from './shipment.component';
import { Ship1Component } from './ship1/ship1.component';
import { Ship2Component } from './ship2/ship2.component';
import { Ship3Component } from './ship3/ship3.component';
import { Ship4Component } from './ship4/ship4.component';
import { ShipConfirmComponent } from './ship-confirm/ship-confirm.component';

const routes: Routes = [
	{ path: 'shipment', 
	  component: ShipmentComponent, 
	  children: [
	  	{ path: 'ship1', component: Ship1Component },
	  	{ path: 'ship2', component: Ship2Component },
	  	{ path: 'ship3', component: Ship3Component },
	  	{ path: 'ship4', component: Ship4Component },
	  	{ path: 'ship-confirm', component: ShipConfirmComponent }
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentRoutingModule { }
