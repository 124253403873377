import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form1583-verification',
  templateUrl: './form1583-verification.component.html',
  styleUrls: ['./form1583-verification.component.css']
})
export class Form1583VerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
