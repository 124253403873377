import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtherServicesRoutingModule } from './other-services-routing.module';
import { OtherServicesComponent } from './other-services.component';
import { PersonalShopperComponent } from './personal-shopper/personal-shopper.component';
import { RemailingComponent } from './remailing/remailing.component';
import { OrderFulfillmentComponent } from './order-fulfillment/order-fulfillment.component';
import { VirtualOfficeComponent } from './virtual-office/virtual-office.component';

@NgModule({
  declarations: [
  	OtherServicesComponent, 
  	PersonalShopperComponent, 
  	RemailingComponent, 
  	OrderFulfillmentComponent, 
  	VirtualOfficeComponent
  ],
  imports: [
    CommonModule,
    OtherServicesRoutingModule
  ]
})
export class OtherServicesModule { }
