import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  /*title = 'can2me';
  currentUser: User;

  constructor(private authenticationService: AuthenticationService) {
  	this.authenticationService.currentUser.subscribe(
  		user => {
  			this.currentUser = user;
  		}
  	);
  }*/

  ngOnInit() {

  }

}
