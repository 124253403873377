import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dim-weight-calculator',
  templateUrl: './dim-weight-calculator.component.html',
  styleUrls: ['./dim-weight-calculator.component.css']
})
export class DimWeightCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
