import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { AuthGuard } from '@app/_guards';

const routes: Routes = [{ 
	  path: 'profile', 
		component: ProfileComponent,
		children: [
			{ path: 'personal-information', component: PersonalInformationComponent },
			{ path: 'change-email', component: ChangeEmailComponent },
			{ path: 'change-password', component: ChangePasswordComponent },
		],
		canActivate: [AuthGuard],
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
