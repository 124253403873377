import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MailForwardingModule } from './mail-forwarding/mail-forwarding.module';
import { OtherServicesModule } from './other-services/other-services.module';
import { AboutUsModule } from './about-us/about-us.module';
import { ShipmentModule } from './shipment/shipment.module';
import { OrderActivityModule } from './order-activity/order-activity.module';
import { BillingModule } from './billing/billing.module';
import { ProfileModule } from './profile/profile.module'; 
import { PreferencesModule } from './preferences/preferences.module'; 
import { PricingModule } from './pricing/pricing.module';
import { BecomeCustomerModule } from './become-customer/become-customer.module';

import { AppRoutingModule } from './app-routing.module';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HelpComponent } from './help/help.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AlertComponent } from './_component/alert/alert.component';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { HomeMenuComponent } from './_component/home-menu/home-menu.component';
import { DashboardMenuComponent } from './_component/dashboard-menu/dashboard-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    HelpComponent,
    SignUpComponent,
    NotFoundComponent,
    AlertComponent,
    LoggedInComponent,
    HomeMenuComponent,
    DashboardMenuComponent,      
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    MailForwardingModule,
    OtherServicesModule,
    AboutUsModule,
    ShipmentModule,
    OrderActivityModule,
    BillingModule,
    ProfileModule,
    PreferencesModule,
    PricingModule,
    BecomeCustomerModule,
    AppRoutingModule,    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
