import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-mail-manager',
  templateUrl: './online-mail-manager.component.html',
  styleUrls: ['./online-mail-manager.component.css']
})
export class OnlineMailManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
