import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { HelpComponent } from './help/help.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedInComponent } from './logged-in/logged-in.component';

import { AuthGuard } from './_guards';

const routes: Routes = [	
	{ path: '', component: HomeComponent },
	{ path: 'home', redirectTo: '', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'sign-up', component: SignUpComponent },	
	{ path: 'logged-in', component: LoggedInComponent, canActivate: [AuthGuard] },	
	{ path: 'help', component: HelpComponent },	
	{ path: '404', component: NotFoundComponent },	
	{ path: '**', redirectTo: '/404' },	
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
