import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BecomeCustomerComponent } from './become-customer.component';
import { CustomerRequirementsComponent } from './customer-requirements/customer-requirements.component';

const routes: Routes = [{ 
	  path: 'become-a-customer', 
		component: BecomeCustomerComponent,
		children: [
			{ path: 'customer-requirements', component: CustomerRequirementsComponent },			
		],		
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BecomeCustomerRoutingModule { }
