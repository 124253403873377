import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { User } from '@app/_models';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	currentUser: User;
  constructor(
  	private authenticationService: AuthenticationService,
  	private router: Router
  ) { 
  	this.authenticationService.currentUser.subscribe( user=> this.currentUser = user);
  }

  ngOnInit() {
  }

  logout(){
  	this.authenticationService.logout();    
  }
}
