import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BecomeCustomerRoutingModule } from './become-customer-routing.module';
import { BecomeCustomerComponent } from './become-customer.component';
import { CustomerRequirementsComponent } from './customer-requirements/customer-requirements.component';

@NgModule({
  declarations: [BecomeCustomerComponent, CustomerRequirementsComponent],
  imports: [
    CommonModule,
    BecomeCustomerRoutingModule
  ]
})
export class BecomeCustomerModule { }
