import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-requirements',
  templateUrl: './customer-requirements.component.html',
  styleUrls: ['./customer-requirements.component.css']
})
export class CustomerRequirementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
