import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ship-confirm',
  templateUrl: './ship-confirm.component.html',
  styleUrls: ['./ship-confirm.component.css']
})
export class ShipConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
