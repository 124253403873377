import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
  	this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  	this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {  	
  	return this.currentUserSubject.getValue();
  }

  login(username: string, password: string) {
  	return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, {username, password})
  		.pipe(map(user => {
  			// login successful if there is a jwt token in the response
  			if (user && user.token) {
  				// store user details and jwt token in local storage to keep user logged in between page refreshes
  				localStorage.setItem('currentUser', JSON.stringify(user));
  				this.currentUserSubject.next(user);
  			}
  			return user;
  		}))
  }

  logout() {
  	// remove user from local storage to log user out
  	localStorage.removeItem('currentUser');
  	this.currentUserSubject.next(null);
  	this.router.navigate(['/login']);
  }
}