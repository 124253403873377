import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PricingComponent } from './pricing.component';
import { MembershipPlansComponent } from './membership-plans/membership-plans.component';
import { ShippingRatesComponent } from './shipping-rates/shipping-rates.component';
import { DimWeightCalculatorComponent } from './dim-weight-calculator/dim-weight-calculator.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';

const routes: Routes = [{ 
	  path: 'pricing', 
		component: PricingComponent,
		children: [
			{ path: 'membership-plans', component: MembershipPlansComponent },
			{ path: 'shipping-rates', component: ShippingRatesComponent },
			{ path: 'dim-weight-calculator', component: DimWeightCalculatorComponent },
			{ path: 'special-offers', component: SpecialOffersComponent },
		],		
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingRoutingModule { }
