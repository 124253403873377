import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OtherServicesComponent } from './other-services.component';
import { PersonalShopperComponent } from './personal-shopper/personal-shopper.component';
import { RemailingComponent } from './remailing/remailing.component';
import { OrderFulfillmentComponent } from './order-fulfillment/order-fulfillment.component';
import { VirtualOfficeComponent } from './virtual-office/virtual-office.component';

const routes: Routes = [
	{ path: 'other-services', 
	  component: OtherServicesComponent,
	  children: [
	  	{ path: 'personal-shopper', component: PersonalShopperComponent },
	  	{ path: 'remailing', component: RemailingComponent },
	  	{ path: 'order-fulfillment', component: OrderFulfillmentComponent },
	  	{ path: 'virtual-office', component: VirtualOfficeComponent },
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OtherServicesRoutingModule { }
